import React from "react"

import DatasetDisplay from "../../components/DatasetDisplay"

// content specific imports
import ogImage from "../../images/data-points/global-2000-sustainability.png"

// tanstack table
import { createColumnHelper } from "@tanstack/react-table"
import { IconFileText } from "@tabler/icons"

let datasetJson = require("../../data/G2000-sustainability.json")

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor(row => row["name"], {
    id: "accountName",
    cell: info => (
      <div className="max-w-[100px] truncate">
        <a
          href={info.row.original["domain"]} rel="nofollow"
          target="_blank"
          className="text-blue underline truncate"
        >
          {info.getValue()}
        </a>
      </div>
    ),
    header: "Company",
  }),
  columnHelper.accessor(row => row["articles"], {
    id: "articles",
    cell: info => (
      <div>
        {info.getValue().map(article => (
          <div className="flex gap-3 items-center max-w-2xl">
            <div>
              <IconFileText size={16} className="text-medium-grey" />
            </div>
            <a
              href={article["url"]} rel="nofollow"
              className="max-w-lg truncate border-b border-b-medium-grey"
              target="_blank"
            >
              {article["title"]}
            </a>
            <div className="flex-1"> </div>
            <div className="text-dark-grey">{article["date"].slice(0, 10)}</div>
          </div>
        ))}
      </div>
    ),
    header: "Top Sustainability Updates, Last 3 months",
  }),
  columnHelper.accessor(row => row["score"], {
    id: "score",
    cell: info => (
      <div className="rounded-full shadow-sm w-4 h-4 border-light-grey">
        {info.getValue()}
      </div>
    ),
    header: "Score",
  }),
]

const dataContext = {
  title: "Global 2000 sustainability monitor",
  description:
    "Top sustainability stories on each company around net zero targets, ESG initiatives, sustainability initiatives and more.",
  blurb:
    "Top sustainability stories for Forbes Global 2000 including key metrics (net zero targets, releasing carbon data, setting carbon neutral targets); activity (ESG initiatives, decarbonisation investments); and news.",
  ctaText: `Get the whole list of ${datasetJson.length} companies and sync it with your CRM.`,
  slug: "global-2000-sustainability",
  freeLimit: 500,
  blogPost: (
    <>
      <p>How are Global 2000 companies investing in sustainability?</p>
      <p>
        Climate change is a big topic and affects all of us urgently. I used
        Tactic to look at how the 2000 biggest companies are approaching
        sustainability in the last 3 months.
      </p>
      <p>
        <strong>Source</strong>: The list of companies came from Forbes Global
        2000 (2021 edition) - an annual ranking of the top 2000 public companies
        in the world, using a weighted assessment of four metrics: sales,
        profit, assets and market value.{" "}
      </p>
      <p>
        To research each company, Tactic looked at news articles, annual
        reports, climate filings, interviews and more to compile this dataset.
      </p>
      <p>
        <strong>Enrichment</strong>: to enrich each company with sustainability
        stories, I researched several dimensions of each company, including key
        metrics (net zero targets, releasing carbon data, setting carbon neutral
        targets); activity (ESG initiatives, decarbonisation investments); and
        news (executives talking about global warming; participating in climate
        events)
      </p>
      <p>
        <strong>Scoring:</strong> whenever we find a sustainability story about
        a company, we scored it based on recency, authority, and whether there
        were specific "juicy" details using machine learning.{" "}
      </p>
      <p>
        The company then receives an overall score by tallying up all the scores
        from the individual stories. We then ranked this list by the score of
        each company.
      </p>
      <p>
        <strong>Summaries</strong>: To help visualise the stories around each
        company, we picked the top three highest scoring/most interesting
        stories on each company, and created a quick summary of links in the
        table.
      </p>
      <p>
        If you'd like to get more details out of this dataset, like whether
        there is a chief sustainability officer at each company,{" "}
        <strong>
          <a href="#cta">contact us for a chat</a>!
        </strong>
      </p>
    </>
  ),
  columns: columns,
  published: "Feb 12, 2023",
}

const DataSet = ({ location }) => {
  return (
    <DatasetDisplay
      location={location}
      ogImage={ogImage}
      dataset={datasetJson}
      dataContext={dataContext}
      fullVersionOverride={true}
    />
  )
}

export default DataSet
